<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Basic Information</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form v-if="show">
            <b-form-group id="input-group-0" label="Id:" label-for="input-0">
              <b-form-input id="input-0" v-model="group.id" disabled></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" label="Name:" label-for="input-1"
              description="The name of the clinical group">
              <b-form-input id="input-1" v-model="group.name" required placeholder="Enter name"
                :disabled="user.userTypeId != 4"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Description:" label-for="input-2"
              description="Description of the clinical group">
              <b-form-textarea id="input-2" v-model="group.description" placeholder="Enter description" rows="3"
                max-rows="6" :disabled="user.userTypeId != 4"></b-form-textarea>
            </b-form-group>

            <b-button @click="onSubmit" variant="primary" v-if="user.userTypeId == 4">Submit</b-button>
            <b-button @click="onDelete" variant="danger" v-if="user.userTypeId == 4">Delete</b-button>
          </b-form>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Clinicians/Researchers/Admins</h3>
          </div>
          <div class="card-toolbar">
            <div class="justify-content-center">
              <b-button @click="onNewOtherUser" v-if="user.userTypeId == 4" variant="info">Add New</b-button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-table hover bordered v-if="group.users" :items="group.users.filter(u => u.userTypeId > 1)"
            :fields="table_otheruser_fields" @row-clicked="(item, index, event) => rowClickHandler(item)">
            <template #cell(userTypeId)="data">
              {{ mapUserTypeId(data.item.userTypeId) }}
            </template>
            <template #cell(name)="data">
              {{ data.item.firstname + ", " + data.item.lastname }}
            </template>
            <template #cell(email)="data">
              {{ data.item.username_email }}
            </template>
          </b-table>
        </div>
      </div>
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Patients</h3>
          </div>
          <div class="card-toolbar">
            <div class="justify-content-center">
              <!-- <b-button v-b-modal.modal-group-add-new-patient variant="info">Add New</b-button> -->
              <b-button @click="onAddNewPatientButtonClick" variant="info">Add New</b-button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-table hover bordered v-if="group.users" :items="group.users.filter(u => u.userTypeId === 1)"
            :fields="table_patient_fields" @row-clicked="(item, index, event) => rowClickHandler(item)">
            <template #cell(name)="data">
              {{ data.item.firstname}}
            </template>
            <template #cell(email)="data">
              {{ data.item.username_email }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <!-- begin::Modal -->
    <b-modal id="modal-group-add-new-patient" title="Add new patient">
      <b-form @submit="onSubmitAddNewPaitent">
        <b-form-group label="Group:">
          <!-- <b-form-input v-model="modal_new_patient_data.group_id" required> -->
          <b-form-select v-model="modal_new_patient_data.group_id" :options="patient_group_options" disabled required></b-form-select>
          <!-- </b-form-input> -->
        </b-form-group>
        <b-form-group label="First Name:" label-for="input-1">
          <b-form-input id="input-1" v-model="modal_new_patient_data.firstname" required placeholder="E.g. James">
          </b-form-input>
        </b-form-group>
        <!-- <b-form-group label="Last Name:" label-for="input-2">
          <b-form-input id="input-2" v-model="modal_new_patient_data.lastname" required placeholder="E.g. Bond">
          </b-form-input>
        </b-form-group> -->
        <b-form-group label="Email:" label-for="input-3">
          <b-form-input id="input-3" v-model="modal_new_patient_data.email"
            placeholder="E.g. james.bond@example.com"></b-form-input>
        </b-form-group>
        <b-form-group label="Phone number:" label-for="input-4">
          <b-form-input id="input-4" v-model="modal_new_patient_data.phone" placeholder="E.g. +447903001234"></b-form-input>
        </b-form-group>
        <b-form-group label="Clinical Reference:" label-for="input-5">
          <b-form-input id="input-5" v-model="modal_new_patient_data.clinical_reference" placeholder="E.g. NHS Number">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Send Access Info via:">
          <b-form-select v-model="modal_new_patient_data.access_info_send_option" :options="send_access_info_send_options" required></b-form-select>
        </b-form-group>
        <b-form-group label="Concent:">
          <b-form-checkbox id="checkbox-1" v-model="modal_new_patient_data.research_concent" name="checkbox-1">
            Patient accepted the research terms and use.
          </b-form-checkbox>
          <b-form-checkbox id="checkbox-2" v-model="modal_new_patient_data.privacy_concent" name="checkbox-2">
            Patient accepted the privacy terms and use.
          </b-form-checkbox>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" variant="danger" @click="onCancelNewPaitent">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="primary" @click="onSubmitAddNewPaitent">
          Submit
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "group",
  data() {
    return {
      show: true,
      group: {
        name: "",
        description: "",
        status: null
      },
      send_access_info_send_options: [
        { text: "Not Now", value: "No Send" },
        { text: "Via Email & Phone", value: "Both" },
        { text: "Via Email only", value: "Email" },
        { text: "Via Phone only", value: "Phone" }
      ],
      patient_group_options: [
        { text: "WE-PREVENT Daily", value: 1},
        { text: "WE-PREVENT Weekly", value: 2},
      ],
      modal_new_patient_data: {
        firstname: "",
        // lastname: "",
        email: "",
        phone: null,
        clinical_reference: null,
        research_concent: false,
        privacy_concent: false,
        group_id: this.$route.params.id
      },
      table_patient_fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
        { key: "email", label: "Email or Phone" }
      ],
      table_otheruser_fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "userTypeId", label: "User Type"}
      ]
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  },
  watch: {
    $route() {
      this.refresh();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Group" }]);
    this.refresh();
  },
  methods: {
    refresh() {
      ApiService.get("/groups/" + this.$route.params.id)
        .then(({ data }) => {
          // console.log(data);
          this.group = data.data[0];
        });
        // .catch(({ error }) => {
        //   console.log(error);
        // });
    },
    onDelete() {
      alert("on Delete");
    },
    onAddNewPatientButtonClick(){
      this.modal_new_patient_data = {
        firstname: "",
        // lastname: "",
        email: "",
        phone: null,
        clinical_reference: null,
        research_concent: false,
        privacy_concent: false,
        group_id: this.$route.params.id
      };
      this.$bvModal.show("modal-group-add-new-patient");
    },
    onCancelNewPaitent(evt) {
      evt.preventDefault();
      this.modal_new_patient_data = {
        firstname: "",
        // lastname: "",
        email: "",
        phone: null,
        clinical_reference: null,
        research_concent: false,
        privacy_concent: false,
        group_id: this.$route.params.id
      };
      this.$bvModal.hide("modal-group-add-new-patient");
    },
    onSubmitAddNewPaitent() {
      ApiService.post("/users/newPatient", this.modal_new_patient_data)
        .then(() => {
          // Success
          this.$bvModal.hide("modal-group-add-new-patient");
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error adding new patient: Please check the correctness of input form.");
        });
    },
    onNewOtherUser() {
      alert("Function not enabled.");
    },
    rowClickHandler(item) {
      if (item.userTypeId == 1) {
        this.$router.push("/patient/" + item.id);
      } else {
        alert("This user is not a patient. Access denied.");
      }
    },
    mapUserTypeId(id) {
      switch (id) {
        case 1:
          return "Patient";
        case 2:
          return "Clinician";
        case 3:
          return "Researcher";
        case 4:
          return "System Admin";
        default:
          return "Unknown";
      }
    }
  }
};
</script>
